import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from "../../components/image"
import shirt from "../../images/shirt.gif"
const Clothing = () => (
  <div style={
    {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }
  } className="clothing-component">
    <SEO title="Clothing coming soon" />

    <h1>COMING SOOON </h1>
    <img src={shirt}/>
  </div>
)

export default Clothing
